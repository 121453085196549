<template>
  <div id="PDPA" class="pdpa">
    <share-header header="นโยบายความเป็นส่วนตัว"></share-header>
    <v-card flat class="pdpa-card">
      <div class="pdpa-card__header">
        นโยบายความเป็นส่วนตัว | Privacy policy
      </div>
      <div class="pdpa-card__content">
        moomall เป็นแพลตฟอร์มช้อปปิ้งออนไลน์ที่ดำเนินการโดย บริษัท ถิร เวนเชอร์
        จำกัด
        ทางเราขอแจ้งนโยบายคุ้มครองข้อมูลส่วนตัวที่ได้รับจากการประมวลผลในระหว่างการให้บริการหรือการเข้าถึงแพลตฟอร์มของบริษัท
        โดยบริษัทจะรวบรวมข้อมูลรวมถึงข้อมูลส่วนบุคคลของท่าน
      </div>
      <div class="pdpa-card__content">
        ข้อมูลส่วนตัวต่างๆ ของท่านที่ให้ไว้บนแพลตฟอร์มของ moomall
        จะถูกจัดเก็บไว้อย่างปลอดภัยและถือว่าท่านได้ยอมรับข้อตกลงนี้เรียบร้อยแล้ว
        โดยท่านสามารถตรวจสอบข้อมูลและการเปลี่ยนแปลงต่างๆ ได้ ดังนี้
      </div>
    </v-card>
    <v-card flat class="pdpa-card">
      <div class="pdpa-card__header">
        เราเลือกจัดเก็บ และใช้งานข้อมูลอย่างไร?
      </div>
      <div class="pdpa-card__content">
        เราจะเลือกจัดเก็บเฉพาะข้อมูลที่จำเป็น เช่น ชื่อหมายเลขบัตรประชา ชน
        หมายเลขบัญชีธนาคาร ที่อยู่สำหรับการจัดส่งสินค้า
        อีเมลและเบอร์โทรศัพท์ข้อมูลเหล่านี้จะถูกจัดเก็บด้วยความปลอดภัยตามมาตรฐานสากลและจะถูกใช้งานเพื่อผลประโยชน์ต่อลูกค้าทุกท่านอย่างเหมาะสม
        เช่นเพื่อการให้ข้อมูลสินค้าเพิ่มเติม
        หรือเพื่อให้บริการหลังการขายเป็นไปด้วยดี ทางแพลตฟอร์มถือว่าข้อมูลต่างๆ
        ที่ท่านได้ให้เรามาได้ถือเป็นกรรมสิทธิ์ของแพลตฟอร์มโดยสมบูรณ์
        หากเกิดข้อมูลหาย หรือเกิดความเสียหาย ด้วยกรณีใดๆ ก็ตาม
        ทางแพลตฟอร์มไม่มีส่วนในการรับผิดชอบใดๆ ทั้งสิ้น
        นอกจากนั้นข้อมูลการเข้าชมเว็บไซต์ของท่านจะถูกบันทึกโดยอัตโนมัติ ได้แก่
        <ul class="list-style-inside mt-2">
          <li>โปรแกรมที่ท่านใช้ค้นหาเรา</li>
          <li>เวลา วันที่ และข้อมูลที่ค้นหา</li>
          <li>ประวัติการเข้าชม</li>
        </ul>
      </div>
      <div class="pdpa-card__header">
        เราเลือกจัดเก็บ และใช้งานข้อมูลอย่างไร?
      </div>
      <div class="pdpa-card__content">
        ทางเราจะจัดเก็บข้อมูลของท่านที่ได้รับมาจากแพลตฟอร์มไว้อย่างปลอดภัยที่สุด
        โดยจะเปิดเผยเพียงข้อมูลที่เป็นประโยชน์ต่อลูกค้าเอง เช่น
        เปิดเผยข้อมูลการจัดส่งสินค้าให้แก่ผู้ทำการจัดส่งสินค้า
        หรือมิฉะนั้นจะเปิดเผยเมื่อถูกขอให้เปิดเผยตามกฎหมายเท่านั้น
      </div>
    </v-card>
    <v-card flat class="pdpa-card">
      <div class="pdpa-card__header">คลังข้อมูลส่วนบุคคล</div>
      <ul class="list-style-inside mb-0">
        <li>
          moomall จะไม่ขายหรือยอมให้บุคคลภายนอกมีส่วนร่วมรู้เห็นข้อมูล
          ส่วนบุคคลของลูกค้าที่ได้เก็บรวบรวมไว้โดยเด็ดขาด
        </li>
        <li>
          ข้อมูลส่วนบุคคลที่ถูกเก็บรวบรวมออนไลน์จะได้รับการเปิดเผยเฉพาะภายในเครือบริษัทของเราเพื่อการดำเนินการภายในเพื่อผลประโยชน์ของผู้ใช้งาน
          moomall เท่านั้น
        </li>
        <li>
          เมื่อท่านได้สร้างบัญชีผู้ใช้กับ moomall นั้น
          ข้อมูลส่วนบุคคลที่เราจะเก็บรวมรวมไว้ อาจมีดังต่อไปนี้
          <ul class="list-style-none">
            <li class="indent">- ชื่อ-นามสกุล</li>
            <li>- ที่อยู่สำหรับการจัดส่งสินค้า</li>
            <li>- ที่อยู่อีเมล</li>
            <li>- ข้อมูลบนหน้าบัตรประชาชน</li>
            <li>- ข้อมูลบัญชีธนาคาร</li>
            <li>- เบอร์โทรศัพท์มือถือ</li>
            <li>- วันเกิด</li>
            <li>- ข้อมูลบัญชีเฟสบุ๊ก</li>
            <li>- ข้อมูลบัญชีไลน์</li>
            <li>- ข้อมูลบัญชีอีเมลกูเกิล</li>
            <li>- เพศ</li>
          </ul>
        </li>
      </ul>
    </v-card>
    <v-card flat class="pdpa-card">
      <div class="pdpa-card__header">
        ข้อมูลส่วนบุคคลที่เราเก็บรวบรวมไว้นั้น จะถูกใช้ดังต่อไปนี้
      </div>
      <ul class="list-style-inside">
        <li>เพื่อการจัดส่งสินค้าที่ท่านสั่งซื้อ</li>
        <li>
          เพื่ออัพเดทข้อมูลการส่งสินค้า
          รวมไปถึงในกรณีที่ลูกค้าต้องการบริการอื่นๆ เพิ่มเติม
        </li>
        <li>เพื่อการให้ข้อมูลสินค้าที่เกี่ยวข้อง</li>
        <li>
          เพื่อดำเนินการจัดส่งสินค้าตามคำสั่งซื้อสินค้าของท่าน
          และการให้บริการต่างๆ พร้อมทั้งให้ข้อมูลผ่านทางเว็บไซต์ของเรา
          ที่ท่านต้องการทราบเพิ่มเติมเกี่ยวกับสินค้าและการบริการของเรานอกเหนือจากบนเว็บไซต์
        </li>
        <li>เพื่อดำเนินการตรวจสอบตัวตนก่อนทำการโอนเงินเข้าบัญชีธนาคาร</li>
        <li>เพื่อดำเนินโอนเงินเข้าบัญชีธนาคาร</li>
        <li>
          นอกจากนี้ เราจะใช้ข้อมูลที่ท่านให้กับเรา
          เพื่อการบริหารจัดการบัญชีผู้ใช้ของท่านกับระบบของเรา,
          ตรวจสอบและส่งข้อมูลการดำเนินธุรกรรมทางด้านการเงินของท่านเพื่อยืนยันการชำระค่าสินค้าออนไลน์นั้นๆ
          เพื่อตรวจสอบจำนวนการดาวน์โหลดข้อมูลจากเว็บไซต์ของเรา
          ทั้งนี้เพื่อการพัฒนาปรับปรุงแผนผังและเนื้อหาภายในเว็บไซต์ให้ตรงกับความต้องการของลูกค้ายิ่งขึ้น,
          รวมทั้งเพื่อให้เรารู้จักผู้ใช้บริการบนเว็บไซต์ของเรามากขึ้น,
          เพื่อที่เราจะสามารถดำเนินการศึกษาและรู้สถิติทางประชากรศาสตร์ของลูกค้าบนเว็บไซต์ของเรามากยิ่งขึ้น
          เพื่ออัพเดตข้อมูลข่าวสารที่เป็นประโยชน์ และตรงกับความต้องการของท่าน
          อันรวมไปถึงข้อมูลสินค้าและบริการจากเรา และด้วยเหตุผลทั้ง หมดที่กล่าวมา
          เรามีความมั่นใจว่าท่านคงเห็นด้วยกับเราและยินยอมให้เราเก็บรักษาข้อมูลส่วนบุคคลของท่านไว้อย่างดี
          เพื่อสิทธิประโยชน์ของท่าน ผู้เป็นลูกค้าของเรา
        </li>
      </ul>
      <div class="pdpa-card__content">
        นอกจากนี้ เราจะใช้ข้อมูลที่ท่านให้กับเรา
        เพื่อการบริหารจัดการบัญชีผู้ใช้ของท่านกับระบบของเรา,
        ตรวจสอบและส่งข้อมูลการดำเนินธุรกรรมทางด้านการเงินของท่านเพื่อยืนยันการชำระค่าสินค้าออนไลน์นั้นๆ
        เพื่อตรวจสอบจำนวนการดาวน์โหลดข้อมูลจากเว็บไซต์ของเรา
        ทั้งนี้เพื่อการพัฒนาปรับปรุงแผนผังและเนื้อหาภายในเว็บไซต์ให้ตรงกับความต้องการของลูกค้ายิ่งขึ้น,
        รวมทั้งเพื่อให้เรารู้จักผู้ใช้บริการบนเว็บไซต์ของเรามากขึ้น,
        เพื่อที่เราจะสามารถดำเนินการศึกษาและรู้สถิติทางประชากรศาสตร์ของลูกค้าบนเว็บไซต์ของเรามากยิ่งขึ้น
        เพื่ออัพเดตข้อมูลข่าวสารที่เป็นประโยชน์ และตรงกับความต้องการของท่าน
        อันรวมไปถึงข้อมูลสินค้าและบริการจากเรา และด้วยเหตุผลทั้ง หมดที่กล่าวมา
        เรามีความมั่นใจว่าท่านคงเห็นด้วยกับเราและยินยอมให้เราเก็บรักษาข้อมูลส่วนบุคคลของท่านไว้อย่างดี
        เพื่อสิทธิประโยชน์ของท่าน ผู้เป็นลูกค้าของเรา
      </div>
      <div class="pdpa-card__content">
        เราขอส่งข้อมูลส่วนตัวของท่านอันได้แก่
        ชื่อและที่อยู่ให้กับบุคคลที่สามในกรณีที่ต้องจัดส่งสินค้า
        เช่นการให้ข้อมูลแก่บริษัทขนส่งสินค้า
        ข้อมูลการสั่งซื้อสินค้าของคุณจะถูกเก็บรวบรวมไว้ในระบบของเรา
        แต่ด้วยเหตุผลทางด้านการรักษาข้อมูลที่เข้มงวด
        เราจึงไม่สามารถเรียกข้อมูลการสั่งซื้อสินค้าของท่านขึ้นมาเองได้โดยตรง
        เว้นแต่ผู้ใช้จะอนุมัติให้เราได้เข้าถึงข้อมูลในส่วนนี้ อย่างไรก็ตาม
        คุณสามารถเข้าถึงข้อมูลเหล่านี้ได้ด้วยตัวเองโดยตรง
        เพียงเข้าสู่ระบบบนเว็บไซต์ด้วยชื่อบัญชีผู้ใช้ของท่านเอง
        ท่านจะสามารถดูข้อมูลการสั่งซื้อสินค้าที่ดำเนินการแล้วได้ทั้งหมด ทั้งที่
        อยู่ในระหว่างการจัดการตามคำสั่งซื้อและที่กำลังรอการจัดส่ง
        ท่านสามารถจัดการตั้งค่าหรือเปลี่ยนแปลงข้อมูลสถานที่จัดส่ง
        ข้อมูลทางธนาคาร และการรับจดหมายข่าวสารได้ด้วยตัวท่านเอง
        ทั้งนี้ท่านจะต้องเก็บข้อมูลที่ใช้ในการเข้าสู่ระบบและข้อมูลส่วนตัวในบัญชีผู้ใช้เป็นความลับมิให้ข้อมูลดังกล่าวผ่านไปถึงบุคคลภายนอกใดๆได้
        เราไม่สามารถรับผิดชอบความเสียหายใดๆที่เกิดขึ้นกับการเข้าสู่ระบบที่ไม่เป็นไปตามความถูกต้องได้
        หากความเสียหายนั้นไม่ได้มีสาเหตุมาจากเรา
      </div>
    </v-card>
    <v-card flat class="pdpa-card">
      <div class="pdpa-card__header">ทำไมถึงโฆษณาออนไลน์ของร้านเราบ่อยนัก?</div>
      <div class="pdpa-card__content">
        เนื่องจากทางร้านได้ใช้บริการ Google AdWords Remarketing และติดตั้ง
        Facebook pixel ไว้ในเว็บไซต์
        ซึ่งอาจทำให้โฆษณาของแพลตฟอร์มของเราปรากฏอยู่บ่อยครั้ง
        โดยบริการดังกล่าวจะจำหมายเลข Browser ของท่าน
        แต่ไม่สามารถระบุตัวตนหรือเข้าถึงคอมพิวเตอร์ของท่านได้
      </div>
    </v-card>
    <v-card flat class="pdpa-card">
      <div class="pdpa-card__header">
        เราสามารถเปลี่ยนแปลงนโยบายของแพลตฟอร์มได้หรือไม่?
      </div>
      <div class="pdpa-card__content">
        เพื่อประโยชน์ของท่าน โปรดติดตามการเปลี่ยนแปลงข้อมูลของนโยบายอยู่เสมอ
        เนื่องจากทางเรามีสิทธิ์ในการเปลี่ยนแปลง
        หรือแก้ไขนโยบายในการรักษาข้อมูลดังกล่าวได้อยู่ตลอดเวลา
        โดยขึ้นอยู่กับความเหมาะสมในช่วงเวลาดังกล่าว
      </div>
    </v-card>
    <v-card flat class="pdpa-card">
      <div class="pdpa-card__header">
        ทางร้านเป็นเจ้าของลิขสิทธิ์ทรัพย์สินทางปัญญาที่ปรากฏอยู่ใน
        เว็บไซต์หรือไม่?
      </div>
      <div class="pdpa-card__content">
        ข้อความ เนื้อหา ภาพ เสียง ครอบคลุมถึงส่วนประกอบต่างๆ ที่ปรากฏบนเว็บไซต์
        และแพลตฟอร์มของเรานั้น ถือเป็นสิทธิ์ของทางร้านโดยชอบด้วยกฎหมาย
        หากฝ่าฝืนหรือมีการลอกเลียน ปลอมแปลง ทำซ้ำ ดัดแปลง เผยแพร่ต่อสาธารณชน
        เพื่อจำหน่ายหรือกระทำการใดๆ อันเป็นการแสวงหาประโยชน์ทางการค้า
        โดยไม่ได้รับอนุญาต ทางแพลตฟอร์มสามารถดำเนินการตามกฎหมายได้ทันที
      </div>
    </v-card>
    <v-card flat class="pdpa-card">
      <div class="pdpa-card__header">ติดต่อเราได้อย่างไร</div>
      <div class="pdpa-card__content pdpa-card__content--no-indent">
        หากต้องการร้องเรียน หรือติชมนโยบายการรักษาข้อมูลดังกล่าว
        สามารถติดต่อเราได้ที่
        <a href="mailto:info@moomall.com">info@moomall.com</a>
      </div>
      <div class="pdpa-card__content pdpa-card__content--no-indent">
        ห่านสามารถดูนโยบายความเป็นส่วนตัวของ Google ได้โดย
        <a
          href="https://developers.google.com/terms/api-services-user-data-policy"
          >คลิกที่นี่</a
        >
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'PDPA',
  data() {
    return {}
  },
  created() {
    // Used this because of it scroll to the end of page
    window.scrollTo(0, 0)
  },
}
</script>

<style lang="scss" scoped>
.pdpa {
  &-card {
    padding: 1rem;
    border-radius: 10px;
    color: #453d3d;
    margin-bottom: 8px;

    &__header {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 8px;
    }

    &__content {
      text-indent: 1rem;
      font-size: 12px;
      margin-bottom: 8px;

      &--no-indent {
        text-indent: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul {
      color: #453d3d;
      font-size: 12px;
      margin-bottom: 8px;

      li {
        margin-bottom: 4px;
      }
    }

    .list-style-none {
      list-style: none;

      li {
        text-indent: 1rem;
      }
    }

    .list-style-inside {
      list-style: inside;
    }

    .indent {
      text-indent: 1rem;
    }
  }
}
</style>
